import { PayloadAction } from '@reduxjs/toolkit';

import { CarPartType, BodyStyle as CarBodyType, AppFeatureType } from 'src/api/globalTypes';
import { IconName } from 'src/apps/NewDriverApp/ui-components/Icon';
import { LANG } from 'src/constants';

import { ImageTypes, WebAppFlows } from './constants';

export enum DriverFacingAppScreens {
  WELCOME_SCREEN = 'WELCOME_SCREEN',
  ACCIDENT_SCENE = 'ACCIDENT_SCENE',
  OTHER_ACCIDENT_PARTY = 'OTHER_ACCIDENT_PARTY',
  ACCIDENT_DRIVER_INFORMATION = 'ACCIDENT_DRIVER_INFORMATION',
  CAR_IDENTIFICATION = 'CAR_IDENTIFICATION',
  DAMAGE_PHOTOS = 'DAMAGE_PHOTOS',
  DRIVER_INFORMATION = 'DRIVER_INFORMATION',
  // CHECKLISTs
  CHECKLIST_CARGO_AREA = 'CHECKLIST_CARGO_AREA',
  CHECKLIST_CAR_CONDITION = 'CHECKLIST_CAR_CONDITION',
  CHECKLIST_CAR_CONDITION_EXTRA_INFO = 'CHECKLIST_CAR_CONDITION_EXTRA_INFO',
  CHECKLIST_COOL_BOX = 'CHECKLIST_COOL_BOX',
  CHECKLIST_DASHBOARD = 'CHECKLIST_DASHBOARD',
  CHECKLIST_DRIVER_INFORMATION = 'CHECKLIST_DRIVER_INFORMATION',
  CHECKLIST_EXTERIOR_DRIVER_SIDE = 'CHECKLIST_EXTERIOR_DRIVER_SIDE',
  CHECKLIST_EXTERIOR_FRONT_SIDE = 'CHECKLIST_EXTERIOR_FRONT_SIDE',
  CHECKLIST_EXTERIOR_FUEL_CAP = 'CHECKLIST_EXTERIOR_FUEL_CAP',
  CHECKLIST_EXTERIOR_PASSENGER_SIDE = 'CHECKLIST_EXTERIOR_PASSENGER_SIDE',
  CHECKLIST_EXTERIOR_REAR_SIDE = 'CHECKLIST_EXTERIOR_REAR_SIDE',
  CHECKLIST_GENERAL_INFORMATION = 'CHECKLIST_GENERAL_INFORMATION',
  CHECKLIST_INTERIOR_FRONT_SEATS = 'CHECKLIST_INTERIOR_FRONT_SEATS',
  CHECKLIST_INTERIOR_REAR_SEATS = 'CHECKLIST_INTERIOR_REAR_SEATS',
  CHECKLIST_OVERVIEW = 'CHECKLIST_OVERVIEW',
  CHECKLIST_COMPLETED = 'CHECKLIST_COMPLETED',
  MECHANICAL_COMPLETED = 'MECHANICAL_COMPLETED',
}

export type ChecklistTemplateQuestionsType = QuestionsInterface | QuestionsInterface[];

export interface ChecklistOption extends Record<string, any> {
  image?: boolean;
  reportDamage?: ReportDamage;
  modal?: ModalProps;
  comment?: ChecklistCheckTextAreaInput;
  form?: ChecklistFormInput[];
  questions?: ChecklistTemplateQuestionsType;
}

export interface ChecklistDamageOptionDamageReportType {
  name: string;
  steps?: StepType[];
}

export interface ReportDamage {
  transitionStatus: string;
  types: ChecklistDamageOptionDamageReportType[];
}

export type StepType = CarPartsStep | DriverCommentStep;

export interface DriverCommentStep extends StepBase {
  required?: boolean;
}

export type viewType = 'front' | 'rear' | 'driver' | 'passenger';

export interface CarPartsStep extends StepBase {
  view?: viewType;
}

export interface StepBase {
  name: string;
}

export interface CheckConfiguration {
  name: string;
  title?: string | null;
  component?: string | null;
  options?: ChecklistOption;
}

export interface ChecklistTemplateWarningMessage {
  title?: string;
  title_i18n?: string;
  title_de?: string;
  title_en?: string;
  title_es?: string;
  title_pl?: string;
  title_tr?: string;
  title_ar?: string;
  title_ru?: string;
  title_ro?: string;
  title_it?: string;

  description?: string;
  description_i18n?: string;
  description_de?: string;
  description_en?: string;
  description_es?: string;
  description_pl?: string;
  description_tr?: string;
  description_ar?: string;
  description_ru?: string;
  description_ro?: string;
  description_it?: string;
}

export interface ChecklistTemplate {
  id: string;
  name: string;
  nameDe?: string;
  nameEn?: string;
  namePl?: string;
  nameRu?: string;
  nameTr?: string;
  nameAr?: string;
  checks: CheckConfiguration[];
  warningMessage?: ChecklistTemplateWarningMessage;
}

export interface LocationState {
  totalSteps: number;
  progressStep: number;
}

export enum ExteriorViewingPointType {
  DRIVER = 'driver',
  FRONT = 'front',
  PASSENGER = 'passenger',
  REAR = 'rear',
  FUEL_CAP = 'fuel_cap',
}

export enum CarTankLevel {
  FULL = 'FULL',
  NEAR_FULL = 'NEAR_FULL',
  HALF = 'HALF',
  NEAR_EMPTY = 'NEAR_EMPTY',
  EMPTY = 'EMPTY',
}

export enum AdBlueLevel {
  FULL = 'FULL',
  NEAR_FULL = 'NEAR_FULL',
  HALF = 'HALF',
  NEAR_EMPTY = 'NEAR_EMPTY',
  EMPTY = 'EMPTY',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum QuestionOptionIconName {
  BREAK_SYSTEM = 'break-system',
  BATTERY = 'battery',
  OIL_LEVEL = 'oil-level',
  BREAK_WEAR = 'break-wear',
  ABS = 'abs',
  MOTOR_CONTROL = 'motor-control',
  TYRE_PRESSURE = 'tyre-pressure',
  DIFFERENTIAL = 'differential',
  SYSTEM_MALFUNCTION = 'system-malfunction',
  COOLANT = 'coolant',
  AIRBAG = 'airbag',
  STOP = 'stop',
  INSPECTION_DUE = 'inspection-due',
  AD_BLUE = 'ad-blue',
}

export enum OverviewPageIconNames {
  CHECK_FAILED = 'check-failed',
  CHECK_PASSED = 'check-passed',
}

export enum RadioEmotionIconName {
  BAD_EMOJI = 'bad-emoji',
  MEDIUM_EMOJI = 'medium-emoji',
  GOOD_EMOJI = 'good-emoji',
}

export interface SelectorSelectedOption {
  value: string;
  label?: string;
  label_en?: string;
  label_de?: string;
  label_es?: string;
  label_i18n?: string;
  icon_color?: string;
}

export enum QuestionOptionInputType {
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  LEVEL = 'level',
  RADIO_EMOTION = 'radio-emotion',
  SERVICE_LIGHT = 'service-light',
  COLLAPSE = 'collapse',
}

export interface QuestionCollapse {
  type: QuestionOptionInputType.COLLAPSE;
  name: string;
  isReversed?: boolean;
  label?: string;
  label_de?: string;
  condition: boolean;
  default: boolean;
  content: QuestionCollapse | ChecklistCheckTextAreaInput;
}

export interface QuestionCheckbox {
  name: string;
  isReversed?: boolean;
  label: string;
  value?: boolean;
  label_en?: string;
  label_de?: string;
  label_es?: string;
  label_i18?: string;
  type?: QuestionOptionInputType.CHECKBOX;
  icon: string;
  icon_color?: string;
}

export interface ChecklistCheckTextAreaInput {
  name: string;
  type?: string;
  label?: string;
  default?: string;
  value?: string;
  label_en?: string;
  label_de?: string;
  label_es?: string;
  label_i18n?: string;
  maxLength?: number;
}

export interface QuestionSelect {
  name: string;
  label?: string;
  options_list_title?: string;
  options_list_title_en?: string;
  options_list_title_de?: string;
  options_list_title_es?: string;
  options_list_title_i18n?: string;
  value?: string;
  isReversed?: boolean;
  label_en?: string;
  label_de?: string;
  label_es?: string;
  label_i18n?: string;
  type?: QuestionOptionInputType.SELECT;
  icon: QuestionOptionIconName;
  icon_color?: string;
  value_options?: SelectorSelectedOption[];
}

export interface QuestionRadioEmotionOption {
  value: string;
  icon: RadioEmotionIconName;
}

export interface QuestionRadioEmotion {
  name: string;
  label?: string;
  type: QuestionOptionInputType.RADIO_EMOTION;
  isReversed?: boolean;
  value?: string;
  required?: boolean;
  label_i18n?: string;
  label_de?: string;
  label_en?: string;
  label_es?: string;
  value_options?: QuestionRadioEmotionOption[];
}

export interface QuestionServiceLight extends Omit<QuestionSelect, 'type'> {
  type: QuestionOptionInputType.SERVICE_LIGHT;
  confirmation?: boolean;
  confirmation_header?: string;
  confirmation_description?: string;
  confirmation_button?: string;
}

export interface QuestionLevel extends Omit<QuestionSelect, 'type'> {
  type: QuestionOptionInputType.LEVEL;
}

export type QuestionOption =
  | QuestionCheckbox
  | QuestionLevel
  | QuestionSelect
  | QuestionRadioEmotion
  | QuestionServiceLight
  | QuestionCollapse;

export interface QuestionsInterface {
  title: string;
  options: QuestionOption[];
  title_en?: string;
  title_de?: string;
  title_es?: string;
  title_i18n?: string;
}

export enum ChecklistFormInputsTypes {
  DRIVER_INPUT = 'driverInput',
  TEXT_AREA = 'textArea',
  CAR_AVAILABILITY = 'carAvailability',
  CHECKBOX = 'checkbox',
  NUMBER = 'number',
  TEXT = 'text',
  DATE = 'date',
}

export enum CheckFormInputNames {
  DRIVER_NAME = 'driverName',
  DRIVER_EMAIL = 'driverEmail',
  DRIVER_PHONE = 'driverPhone',
  TEMPERATURE = 'temperature',
  FRONT_TYRE_TREAD = 'frontTyreTread',
  REAR_TYRE_TREAD = 'rearTyreTread',
  HANDOVER_BY = 'handoverBy',
  HANDOVER_TO = 'handoverTo',
  HANDOVER_DATE = 'handoverDate',
  HANDOVER_PLACE = 'handoverPlace',
  COST_CENTER = 'costCenter',
}

export type ChecklistFormInputNames = CheckFormInputNames;

export type ChecklistIconNames =
  | QuestionOptionIconName
  | RadioEmotionIconName
  | OverviewPageIconNames;

export enum ChecklistFormInputValidatorName {
  IS_NAME = 'isName',
  IS_EMAIL = 'isEmail',
  IS_PHONE = 'isPhone',
  IS_MAX_TWO_DIGIT_NUMBER = 'isMaxTwoDigitNumber',
  IS_ONE_OF = 'isOneOf',
}

export interface ChecklistFormisOneOfValidatorConfig {
  otherField?: string | string[];
}

export interface ChecklistFormInputValidator {
  [ChecklistFormInputValidatorName.IS_NAME]?: boolean;
  [ChecklistFormInputValidatorName.IS_EMAIL]?: boolean;
  [ChecklistFormInputValidatorName.IS_PHONE]?: boolean;
  [ChecklistFormInputValidatorName.IS_MAX_TWO_DIGIT_NUMBER]?: boolean;
  [ChecklistFormInputValidatorName.IS_ONE_OF]?: ChecklistFormisOneOfValidatorConfig;
}

export type ChecklistFormInputFnValidator =
  | ChecklistFormInputValidatorName
  | ChecklistFormInputValidatorName[]
  | ChecklistFormInputValidator;

export interface ChecklistFormInput {
  name: ChecklistFormInputNames;
  value?: string | boolean;
  type?: ChecklistFormInputsTypes;
  required?: boolean;
  validate?: ChecklistFormInputFnValidator;
  label?: string;
  label_en?: string;
  label_de?: string;
  label_es?: string;
  label_i18?: string;
}

export enum DashboardConfigurableFields {
  ADBLUE_LEVEL = 'adBlueLevel',
  TUV = 'tuv',
  LAST_INSPECTION = 'lastInspection',
  BATTERY_LEVEL = 'batteryLevel',
  MILEAGE = 'mileage',
  PETROL_LEVEL = 'petrolLevel',
}

export type DashboardFormFieldsNames =
  (typeof DashboardConfigurableFields)[keyof typeof DashboardConfigurableFields];

export interface ChecklistDashboardFormInput {
  name: DashboardFormFieldsNames;
  required: boolean;
  type?: 'text' | 'number' | 'level';
}

export enum ServiceLightNames {
  BREAK_SYSTEM = 'breakSystem',
  BATTERY = 'battery',
  OIL_LEVEL = 'oilLevel',
  BREAK_WEAR = 'breakWear',
  ABS = 'abs',
  MOTOR_CONTROL = 'motorControl',
  TYRE_PRESSURE = 'tyrePressure',
  DIFFERENTIAL = 'differential',
  SYSTEM_MALFUNCTION = 'systemMalfunction',
  COOLANT = 'coolant',
  AIRBAG = 'airbag',
  STOP = 'stop',
  INSPECTION_DUE = 'inspectionDue',
  AD_BLUE = 'adBlue',
}

export enum ChecklistRadioEmotionNames {
  EXTERIOR_CLEANLINESS = 'exteriorCleanliness',
  INTERIOR_CLEANLINESS = 'interiorCleanliness',
  CARGO_AREA_CLEANLINESS = 'cargoAreaCleanliness',
}

export enum ChecklistCheckboxNames {
  LITTER_REMOVED = 'isLitterRemoved',
  INSPECTION_DUE = 'inspectionDue',
}

export enum ChecklistCommentNames {
  CAR_INFORMATION_COMMENT = 'additionalCarInformationComment',
}

export type DriverInformationGroupDirection = 'row' | 'column';

export interface FieldConfig {
  name: string;
  type?: string;
  isRequired?: boolean;
  translationKey?: string;
  isHidden?: boolean;
  groupName?: string;
  groupDirection?: DriverInformationGroupDirection;
  subfields?: FieldConfig[];
  space?: number;
  validation?: {
    [key: string]: {
      value: string | number | boolean;
      validationText: string;
    };
  };
  placeholder?: string;
  checkboxLabel?: string;
  className?: string;
}

export interface ReactPhoneInputRef {
  handleInputFocus: () => void;
  numberInputRef: HTMLInputElement;
}

export interface InputIconProps {
  width: string;
  height: string;
  isRequired?: boolean;
}

export interface DamagePhotosCameraContext {
  imageType: ImageTypes;
  id?: string;
  isLocalDeletionOnly?: boolean;
}

export interface ReportedDamageProps {
  onSubmit?: (carPart: CarPartType) => void;
}

export interface AreaProps {
  handlePartClick?: (carPartType: CarPartType) => void;
  carBodyType?: CarBodyType | null;
}

export interface CutOutProps {
  withHistory?: boolean;
  processNext?: (e: React.SyntheticEvent) => void;
  isWithBackButton?: boolean;
  buttonTitle?: string;
  isButtonDisabled?: boolean;
  headerTitle?: string;
  onWheelSelection?: (part: CarPartType) => void;
  carBodyType?: CarBodyType | null;
}

export interface ModalProps {
  iconName?: IconName;
  title?: string;
  description?: string;
  button?: string;
  isOpenOnEnter?: boolean;
}

export interface ExtendedModalProps extends ModalProps {
  close: () => void;
  confirm: () => void;
  open: () => void;
  isOpen?: boolean;
}

export interface IconProps {
  className?: string;
}

export type IsWebAppFlowAllowed = (checkFlow: WebAppFlows | WebAppFlows[]) => boolean;
export type HasFeature = (featureName: string) => boolean;

export interface FlowItem {
  isEnabled?: boolean | null;
  actionPayload: PayloadAction<unknown, string>;
}

export type ObjectValues<T> = T[keyof T];

export interface I18nTranslation {
  [key: string]: string | I18nTranslation;
}

export type I18nTranslations = Record<LANG, I18nTranslation>;

export type AccidentModules =
  | AppFeatureType.ACCIDENT_REPORT
  | AppFeatureType.ACCIDENT_WITHOUT_ANOTHER_CAR_REPORT;
