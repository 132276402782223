import lazyWithPreload from '../../utils/lazyWithPreload';

const ArabicFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "ArabicFlagIcon" */ 'src/img/flags/reexports/px-arabic'),
);
const BritishFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "BritishFlagIcon" */ 'src/img/flags/reexports/px-english'),
);
const FrenchFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "FrenchFlagIcon" */ 'src/img/flags/reexports/px-french'),
);
const GermanFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "GermanFlagIcon" */ 'src/img/flags/reexports/px-german'),
);
const PolishFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "PolishFlagIcon" */ 'src/img/flags/reexports/px-polish'),
);
const RomanianFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "RomanianFlagIcon" */ 'src/img/flags/reexports/px-romanian'),
);
const RussianFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "RussianFlagIcon" */ 'src/img/flags/reexports/px-russian'),
);
const TurkishFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "TurkishFlagIcon" */ 'src/img/flags/reexports/px-turkish'),
);

const ItalianFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "ItalianFlagIcon" */ 'src/img/flags/reexports/px-italian'),
);

const UkrainianFlagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "UkrainianFlagIcon" */ 'src/img/flags/reexports/px-ukrainian'),
);

export const lngOptions = [
  {
    name: 'de',
    nativeName: 'Deutsch',
    icon: GermanFlagIcon,
  },
  {
    name: 'en',
    nativeName: 'English',
    icon: BritishFlagIcon,
  },
  {
    name: 'pl',
    nativeName: 'Polski',
    icon: PolishFlagIcon,
  },
  {
    name: 'tr',
    nativeName: 'Türkçe',
    icon: TurkishFlagIcon,
  },
  {
    name: 'ar',
    nativeName: 'العربية',
    icon: ArabicFlagIcon,
  },
  {
    name: 'es',
    nativeName: 'Español',
  },
  {
    name: 'ru',
    nativeName: 'Русский',
    icon: RussianFlagIcon,
  },
  {
    name: 'ro',
    nativeName: 'Română',
    icon: RomanianFlagIcon,
  },
  {
    name: 'fr',
    nativeName: 'Français',
    icon: FrenchFlagIcon,
  },
  {
    name: 'it',
    nativeName: 'Italiano',
    icon: ItalianFlagIcon,
  },
  {
    name: 'ua',
    nativeName: 'Українська',
    icon: UkrainianFlagIcon,
  },
];
