import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
  resetCarIdentification,
  carIdentificationSetCarInfo,
  carIdentification,
  carIdentificationFailure,
  carIdentificationSuccess,
} from '../actions';
import { CarInfo } from '../types/Types';

export interface CarIdentificationReducer {
  carInfo: CarInfo;
  savingError: Error | null;
  saving: boolean;
}

const initialState: CarIdentificationReducer = {
  saving: false,
  savingError: null,
  carInfo: {
    id: '',
    licensePlate: '',
    vin: '',
    firstRegistration: null,
    make: '',
    isPool: null,
    model: '',
    bodyStyle: null,
  },
};

export const carIdentificationReducer = createReducer(initialState, {
  [resetCarIdentification.type]: () => {
    return initialState;
  },

  [carIdentificationSetCarInfo.type]: (state, action: PayloadAction<{ carInfo: CarInfo }>) => {
    state.carInfo = action.payload.carInfo;
  },
  [carIdentification.type]: state => {
    state.saving = true;
  },
  [carIdentificationFailure.type]: (state, action: PayloadAction<{ error: Error }>) => {
    state.saving = false;
    state.savingError = action.payload.error;
  },
  [carIdentificationSuccess.type]: state => {
    state.saving = false;
    state.savingError = null;
  },
});
