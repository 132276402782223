import React from 'react';

import cn from 'classnames';
import ReactDOM from 'react-dom';

import { ReactComponent as PxCloseLargeIcon } from 'src/img/other-icons/px-close-large.svg';

import styles from './BottomModal.module.scss';
import Text from '../Text';

const modalRoot = document.getElementById('modal') as HTMLDivElement;

interface Props {
  title: string;
  onClose: (e: React.MouseEvent) => void;
  isOpen?: boolean;
  startAdornment?: JSX.Element;
  className?: string;
  isDisabled?: boolean;
}

const BottomModal: React.FC<Props> = ({
  title,
  onClose,
  children,
  startAdornment,
  isOpen = true,
  className,
  isDisabled,
}) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={cn(styles.modalOverlay, { [styles.disabledOverlay]: isDisabled }, className)}
      onClick={onClose}
    >
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalTitle}>
          {startAdornment && <div className={styles.startAdornment}>{startAdornment}</div>}
          <Text type="body-base-medium" className={styles.text}>
            {title}
          </Text>
          <div onClick={onClose}>
            <PxCloseLargeIcon
              className={cn(styles.closeIconSvg, { [styles.disabledIcon]: isDisabled })}
            />
          </div>
        </div>
        <div className={styles.modalPayload}>{children}</div>
      </div>
    </div>,
    modalRoot,
  );
};

export { BottomModal };
